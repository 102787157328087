<template>
  <div>
    <PendingPayment title="PendingPayment"> </PendingPayment>

    <Migration title="Migração" v-if="!isGreenn"> </Migration>
    <LastDay v-if="(isFreeTrial || showModal) && !isGreenn"></LastDay>
    <LeadsOvertaking title="LeadsOvertaking"></LeadsOvertaking>
    <ValidatePlan title="ValidatePlan"></ValidatePlan>
    <VerifyPanel title="VerifyPanel"> </VerifyPanel>
    <div fluid class="p-0" v-if="isAuthenticated && notbard">
      <b-row
        id="app"
        :data-mobile="isMobile ? 'true' : 'false'"
        class="full-height p-0 m-0"
      >
        <TrialFlag @lastDay="lastDay()" v-if="isFreeTrial && !isGreenn"></TrialFlag>
        <min-sidebar v-if="isMobile && !isGreenn" />
        <maxSidebar v-show="!dynamicMenuBar" v-if="!isMobile && !isGreenn" :isFreeTrial="isFreeTrial"/>

        <GrSideBar v-show="!dynamicMenuBar" v-if="isGreenn" :isFreeTrial="isFreeTrial">
        </GrSideBar>

        <main id="scrollmain" class="main" :class="{ 'mobile': isMobile, 'padding-menubar': !dynamicMenuBar }">
          <transition mode="out-in">
            <slot></slot>
          </transition>
        </main>
      </b-row>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import GrSideBar from "@/components/Side/GrSideBar/GrSideBarLayout";

import ResourceCount from "@/services/resources/RecursesService";
const resourceCount = ResourceCount.build();

import BackupService from "@/services/resources/BackupService";
const serviceBackup = BackupService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import UserService from "@/services/resources/UsersService";
const UserSettings = UserService.build();

// import { EventBus } from "@/main.js";
// Seller
import ValidatePlan from "../components/Dashboard/ValidatePlan.vue";
import PendingPayment from "@/components/Apps/ProductsIntegration/PendingPayment";
import VerifyPanel from "@/components/Dashboard/VerifyPanel";
import LeadsOvertaking from "@/components/Apps/LeadsOvertaking";
import Migration from "@/components/Apps/Migration";
import maxSidebar from "@/components/Sidebar/index";
import minSidebar from "@/components/Sidebar/Toggle-sidebar/index";
import TrialFlag from "@/components/Sidebar/TrialFlag.vue";
import LastDay from "@/components/Sidebar/LastDay.vue"

import LeadService from "@/services/resources/LeadService";
const serviceLead = LeadService.build();

import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();


import { jwtDecode } from 'jwt-decode';

export default {
  name: "app",
  components: {
    maxSidebar,
    minSidebar,
    ValidatePlan,
    PendingPayment,
    VerifyPanel,
    LeadsOvertaking,
    Migration,
    LastDay,
    TrialFlag,
    GrSideBar
  },
  data() {
    return {
      contracts: '',
      showModal: false,
      isNotification: false,
      notbard: false,
      client: {
        width: 0,
      },
      sidebar: false,
      recursoLeads: 0,
    };
  },
  computed: {
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    dynamicMenuBar(){
      return this.$route.name == "CRM_id" || this.$route.name == "CRM"
    },
    isFreeTrial() {
      if (!this.contracts) {
        return false
      }
      let validContracts = this.contracts.filter(contract => contract.status == -5 && contract.produto_id == 132);
      let activieContracts =  this.contracts.filter(contract => contract.produto_id >= 118 && (contract.status == 1)|| (contract.status == 2) || (contract.status == 0))
      if (validContracts.length && !activieContracts.length) {
        return true
      }
      return false
    },
    active() {
      return this.$route.name;
    },
    getWindowWidth() {
      return this.client.width > 900;
    },
    isMobile() {
      return this.client.width <= 768;
    },
    isAuthenticated() {
      return this.isIframe || (this.$route.name != "Login" && this.$store.getters.isAuthenticated);
    },
    isIframe() {
      return window.self !== window.top
    },
  },
  async created() {
    // desbloqueando rotação
    if (this.detectOs() == "Android") {
      window.screen.orientation.lock("portrait");
    }

    let setting = await serviceGlobalSettings.read('greenn-sales');

    if(setting && setting.meta_value && setting.meta_value == 'true'){
      let url = process.env.BASE_URL_GREENN;
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }

      if (!url.includes(window.location.host)) {
        if(this.$store.getters.isAuthenticated){
          try {
            let resp = await UserSettings.read('getRdr');
            window.open(`${url}/adm/login?rdrk=${resp.ck}`,'_self');
            return;
          } catch (error) {
            window.open(`${url}${window.location.pathname}`,'_self');
          }
          return;
        }
        window.open(`${url}${window.location.pathname}`,'_self');
        return;
      }
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // EventBus.$on("errorNotify", (data) => {
    //   this.$bvToast.toast(data, {
    //     title: 'Teste1',
    //     variant: "danger",
    //     autoHideDelay: 5000,
    //     appendToast: true,
    //   });
    // });

    // // notificação perfil incompleto
    // EventBus.$on("incomplete", () => {
    //   this.$bvToast.toast('Teste2', {
    //     title: 'Teste3',
    //     variant: "info",
    //     autoHideDelay: 8000,
    //     appendToast: true,
    //   });
    // });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    lastDay() {
      this.$bvModal.show("lastDay");
    },
    verifyResource() {
      if (
        (this.$store.getters.isAuthenticated &&
          !localStorage.getItem("resources")) ||
        localStorage.getItem("resources") === "{}" ||
        localStorage.getItem("resources") === "[]"
      ) {
        this.$store.dispatch("getResource");
      }
    },
    verifySubdomain() {
      if (this.$store.getters.isAuthenticated) {
        let hasAppeared = Cookies.get('fresh_subdomain')
        if ( this.$store.getters.tenantSubdomain === "adm" &&
          !hasAppeared &&
          this.$store.getters.user.user.email_verifed_at != 0 &&
          !/migracao/.test(window.location.pathname) )
          {
            this.$bvModal.show("verifySubdomain");
          }
        return;
      }
    },
    pendingPayment() {
      if (
        this.$store.getters.isAuthenticated &&
        !sessionStorage.getItem("userContracts")
      ) {
        resourceCount
          .read("/contrato")
          .then((resp) => {
            let contracts = resp;
            this.contracts = resp
            sessionStorage.setItem("userContracts", JSON.stringify(resp));
            if (contracts.some(item => (item.status === 1 || item.status === 0 || item.status === 2 || item.status === -5) && item.produto_id >= 118)) {
              // Caso o status do usuário seja de pagamento atrasado, ele mostra um modal com esse aviso 
              let hasAppeard = Cookies.get("modalShown")
              if (contracts.find(item => item.status == 2 && item.produto_id >= 118) && !hasAppeard) {
                this.$bvModal.show("PendingPayment");
                Cookies.set("modalShown", "true", { expires: 0.5 });
              }
              return
            }
            if (contracts.some(item => item.produto_id === 132)) {
              this.showModal = true
              this.$nextTick(() => {
                this.lastDay()
              });
              return
            }
            this.$store.dispatch("logoutRequest");
            this.$bvModal.show("ValidatePlan");
            Cookies.remove("access_token");
          })
          .catch((err) => {});
      } else if (sessionStorage.getItem("userContracts")) {
        let contracts = JSON.parse(sessionStorage.getItem("userContracts"));
        this.contracts = JSON.parse(sessionStorage.getItem("userContracts"))
        if (contracts.some(item => (item.status === 1 || item.status === 0 || item.status === 2 || item.status === -5) && item.produto_id >= 118)) {
          // Caso o status do usuário seja de pagamento atrasado, ele mostra um modal com esse aviso 
          let hasAppeard = Cookies.get("modalShown")
          if (contracts.find(item => item.status == 2 && item.produto_id >= 118) && !hasAppeard) {
            this.$bvModal.show("PendingPayment");
            Cookies.set("modalShown", "true", { expires: 0.5 });
          }
          return
        }
        if (contracts.some(item => item.produto_id === 132)) {
          this.showModal = true
          this.$nextTick(() => {
            this.lastDay()
          });
          return
        }
        this.$store.dispatch("logoutRequest");
        this.$bvModal.show("ValidatePlan");
        Cookies.remove("access_token");
      }
    },
    leadsOvertaking() {
      if (this.$store.getters.isAuthenticated) {
        var exist = sessionStorage.getItem("LeadsOvertaking");
        if (!exist) {
          serviceLead
            .read("/count")
            .then((resp) => {
              sessionStorage.setItem("LeadsOvertaking", resp.count);
              if (
                this.$store.getters.recursos.nleads < resp.count &&
                this.$route.name === "Leads"
              ) {
                this.$bvModal.show("LeadsOvertaking");
              }
            })
            .catch((err) => {});
        }
      }
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    handleResize() {
      // padrão
      if (this.client.width === 0) this.client.width = window.innerWidth;
      if (this.client.width > 0) this.client.width = window.screen.width;
    },
    removeNotification() {
      // gN vem do script inseriro pelo load.js -->>
      if (window.gN && this.isNotification) {
        if (!gN || !gN.disconnectUser || !gN.removeEventsListener || this.$route.name == "Dashboard") {
          return;
        }
        
        gN.disconnectUser();
        gN.removeEventsListener();

        var base = process.env.NOTIFY_HOST;
        // Selecione todos os elementos <script> inseridos pela função setNotification() -->>
        var notificationJs = ["load.js", "gn.js", "moment.js"];
        notificationJs.forEach((link) => {
          var script = document.querySelector(`script[src='${base + link}']`);
          if (script) {
            script.parentNode.removeChild(script);
          }
        });

        var notificationJs2 = [
          "/socket.io/socket.io.js",
          "https://unpkg.com/@popperjs/core@2",
          "https://unpkg.com/tippy.js@6",
        ];
        notificationJs2.forEach((link) => {
          var script = document.querySelector(`script[src='${link}']`);
          if (script) {
            script.parentNode.removeChild(script);
          }
        });

        var notificationJs3 = ["assets/moment.js", "socket.io/socket.io.js"];
        notificationJs3.forEach((link) => {
          var script = document.querySelector(`script[src='${base + link}']`);
          if (script) {
            script.parentNode.removeChild(script);
          }
        });

        // var socketScript = document.querySelector("script:not([src])");
        // if (socketScript) {
        //   socketScript.parentNode.removeChild(socketScript);
        // }

        // Remova os elementos <link> se eles existirem -->>
        var notificationCss = [
          "assets/css/all.css",
          "assets/css/index.css",
          "assets/index.css",
        ];
        notificationCss.forEach((link) => {
          var script = document.querySelector(`link[href^='${base + link}']`);
          if (script) {
            script.parentNode.removeChild(script);
          }
        });

        // Remove o link do google fonts -->>
        var google_font = document.querySelector(
          `link[href^='https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap']`
        );
        if (google_font) {
          google_font.parentNode.removeChild(google_font);
        }

        // Remove o HTML -->>
        var notificationsOut = document.getElementById("notifications_out");
        var notificationButton = document.getElementById("notification_button");

        if (notificationsOut && notificationButton) {
          notificationsOut.parentNode.removeChild(notificationsOut);
          notificationButton.id = "";
          notificationButton.innerHTML = "";
        }
      }
      this.isNotification = false;
    },
    async setNotification() {
      if(this.isGreenn){
        return;
      }
      var token = Cookies.get("access_token");
      if (!token || this.isNotification || this.isIframe) {
        return;
      }
      
      const decodedToken = jwtDecode(token);
      if(!decodedToken || (decodedToken && !decodedToken.tenant_id && ! decodedToken.user_id)){
        return;
      }

      token = token.replace("Bearer ", "");
      var input_auth = document.getElementById("auth");
      input_auth.value = token;

      var base = process.env.NOTIFY_HOST;
      var loadScript = document.createElement("script");
      await this.$nextTick()
      loadScript.src = `${base}load.js`;
      // Adiciona o script ao documento -->>
      document.head.appendChild(loadScript);
      this.isNotification = true;
    },
    setCrisp() {
      if(this.$store.getters.recursos.nchat == 0){
        return
      }
      let name = `${this.$store.getters.user.user.first_name} ${this.$store.getters.user.user.last_name}`;
      let email = this.$store.getters.user.user.email;

      window.$crisp = [];
      window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;
      window.$crisp.push(["set", "user:nickname", [name]]);
      window.$crisp.push(["set", "user:email", [email]]);
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["set", "session:segments", [["gdigital"]]]);

      setTimeout(() => {
        let crispScript = document.createElement("script");
        crispScript.setAttribute("src", "https://client.crisp.chat/l.js");
        crispScript.setAttribute("id", "1WZET722PJibpmL_crisp");
        document.getElementsByTagName("head")[0].appendChild(crispScript);
      }, 1000);
    },
    verifyLimitResource() {
      if (this.$store.getters.isAuthenticated) {
        var count = parseInt(sessionStorage.getItem("LeadsOvertaking"));
        var ModalLeadsOvertaking = sessionStorage.getItem(
          "ModalLeadsOvertaking"
        );

        if (ModalLeadsOvertaking && ModalLeadsOvertaking == "true") {
          return;
        }

        if (
          count > this.$store.getters.recursos.nleads &&
          this.$route.name === "Leads"
        ) {
          this.$bvModal.show("LeadsOvertaking");
        }
      }
    },
    migrationProccess() {
      if (
        this.$store.getters.user.user.level != "admin" &&
        this.$store.getters.user.user.level != "owner"
      ) {
        return;
      }

      this.checkIfHasExport();
    },
    checkIfHasExport() {
      serviceBackup.create({ only_check: true, page: true }).then((resp) => {
        if (resp == false) {
          this.$router.push({ name: "migration" });
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      var routers_not_notification = [
        "Login2",
        "LoginRdr",
        "Login",
        "verificar-email",
        "verificar-email-token",
        "Recuperar Senha",
        "RecuperarSenhaTk",
        "Cancelar Reunião",
        "Integração",
        "IntegraçãoTk",
        "selecionar-painel",
        "404",
        "IsMobile",
        "Desiscrever email",
        "Acesso negado",
      ];

      var routers_reload_notification = ["CRM_id", "CampanhasEtapas"];

      if (routers_not_notification.includes(this.$route.name)) {
        this.removeNotification();
      } else if (
        routers_reload_notification.includes(this.$route.name) &&
        this.isNotification
      ) {
        this.removeNotification();
        this.setNotification();
      } else if (routers_reload_notification.includes(this.$route.name)) {
        this.setNotification();
      } else if (
        (from.name == "CRM_id" && this.isNotification) ||
        (from.name == "CRM" && this.isNotification) ||
        (from.name == "CampanhasEtapas" && this.isNotification)
      ) {
        this.removeNotification();
        this.setNotification();
      } else if (from.name === "CRM" && to.name !== "CRM_id") {
        this.removeNotification();
        this.setNotification();
      } else {
        this.setNotification();
      }

      if (typeof to.meta.sidebar != "undefined") {
        this.notbard = to.meta.sidebar;
      } else {
        this.notbard = true;
      }

      if (this.isIframe) {
        return
      }

      if (
        this.$route.name.toLocaleLowerCase() != "selecionar-painel" &&
        this.$route.name.toLocaleLowerCase() != "login"
      ) {
        this.verifyLimitResource();
        this.pendingPayment();
        this.leadsOvertaking();
        this.verifySubdomain();
      }
      this.verifyResource();
      document.querySelector("body").style = "overflow: hidden !important; ";
      const element = document.getElementById("scrollmain");
      if (element) {
        element.scrollTop = 0;
      }
    },
  },
  mounted() {
    if (!this.isIframe) {
      this.leadsOvertaking();
      if (this.$store.getters.isAuthenticated) {
        this.setCrisp();
      }
    }
  },
};
</script>

<style lang="scss">
@import "../assets/scss2/main.scss";

* {
  color: var(--gray01);
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.form-group {
  margin: 0 !important;
}
.tranz {
  transform: translateZ(0);
}
.full-height {
  height: 100vh;
}
#app {
  flex-wrap: wrap;
  overflow: hidden;
}
.padding-menubar{
  padding: 0 40px;
}
.main {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100%);
}
.main.mobile {
  margin-top: 70px;
  padding: 0 15px;
  max-width: 100% !important;
  height: calc(100% - 70px) !important;
}
.main::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  // border-radius: 20px;
}
.main::-webkit-scrollbar-track {
  background-color: transparent;
}
.main::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
[data-anima="mobile"] {
  animation: op 0.5s forwards;
}
@keyframes op {
  from {
    opacity: 0;
    transform: rotate(180deg);
  }
  to {
    opacity: 1;
    transform: rotate(0);
  }
}
[data-anima="top"] {
  animation: showTop 0.5s forwards;
}
@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}
@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}
@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="right"] {
  animation: showRight 0.5s forwards;
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="sidebar"] {
  animation: showSidebar 0.3s forwards;
}
@keyframes showSidebar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

header .sub-menu {
  margin-top: 0 !important;
}

.menu-relative .sub-menu3 {
  top: inherit !important;
  position: fixed !important;
  margin-top: 0px !important;
}

.container {
  max-width: 1150px !important;
  margin: 0 auto !important;
  padding: 0 !important;
}

// transação de entrada
.v-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
.v-enter-active {
  transition: all 0.3s;
}

@keyframes preload {
  from {
    background: #f7f7f7;
  }

  to {
    background: rgba(237, 237, 240, 0.3);
  }
}
.wrapper {
  background: #fff !important;
  border: 0.5px #ededf0 solid !important;
  border-radius: 10px !important;
}
.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 10px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}
.total-vendas,
.total-itens {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
}
.total-vendas {
  display: flex;
  align-items: center;
  margin: 0px;
}
.total-vendas span {
  font-size: 20px;
  font-weight: 600;
  color: var(--greenn);
  margin-left: 5px;
}

.total-vendas.info {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray01);
}
// box total
.box-total {
  background: var(--greenn2);
  padding: 30px !important;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--greenn);
}
@media screen and (max-width: 768px) {
  .box-total {
    padding: 20px !important;
    margin: 10px auto !important;
  }
  .total-vendas,
  .total-itens {
    font-size: 14px;
  }
  .total-vendas span {
    font-size: 16px;
  }
  .total-vendas.info {
    font-size: 13px;
  }
}
.ql-toolbar.ql-snow {
  border-color: #ededf0 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: Montserrat, sans-serif;
}
.ql-container.ql-snow {
  border-color: #ededf0 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: Montserrat, sans-serif;
}
.ql-editor {
  min-height: 12vh !important;
}
// .ql-editor p {
//   font-size: 14px !important;
// }

.sweet-container {
  padding: 45px 35px !important;
  border-radius: 10px;
}
.sweet-container button {
  margin-bottom: 0 !important;
}
.sweet-container h2 {
  font-size: 20px !important;
  margin-bottom: 20px !important;
}
.sweet-container #swal2-content {
  font-size: 16px !important;
  color: #81858e !important;
  line-height: 1.5 !important;
}
.sweet-container .swal2-checkbox {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .swal2-header {
    align-items: flex-start !important;
  }
  .sweet-container h2 {
    font-size: 16px !important;
    text-align: left !important;
  }
  .sweet-container #swal2-content {
    font-size: 14px !important;
    text-align: left !important;
  }
}
.dropdown-item {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background: var(--greenn) !important;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.v-enter {
  transform: translate3d(0, -20px, 0);
}
.v-leave-to {
  transform: translate3d(0, 20px, 0);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
.invalid-feedback {
  color: var(--red) !important;
}

.image-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 190px !important;
  max-width: 190px !important;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 10px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("../assets/img/icons/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: var(--greenn);
}
.b-toast-success .toast-header strong::before {
  background: url("../assets/img/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: var(--greenn) !important;
  color: var(--greenn) !important;
}
.b-toast-success .toast .toast-header {
  color: var(--greenn) !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: var(--bluee);
}
.b-toast-info .toast-header strong::before {
  background: url("../assets/img/icons/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: var(--bluee) !important;
  color: var(--bluee) !important;
}
.b-toast-info .toast .toast-header {
  color: var(--bluee) !important;
  background-color: #f4f5fd !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: var(--red);
}
.b-toast-danger .toast-header strong::before {
  background: url("../assets/img/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.b-toast-danger .toast .toast-header {
  color: var(--red) !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: var(--orange);
}
.b-toast-warning .toast-header strong::before {
  background: url("../assets/img/icons/notify_warning.svg") no-repeat center;
}
.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: var(--orange) !important;
  color: var(--orange) !important;
}
.b-toast-warning .toast .toast-header {
  color: var(--orange) !important;
  background-color: #fffcf5 !important;
  border: none !important;
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: var(--gray01);
}
.toast-header strong::before {
  background: url("../assets/img/icons/notify_default.svg") no-repeat center;
}
.toast {
  background-color: #f6f6f7 !important;
  border-color: var(--gray01) !important;
  color: var(--gray01) !important;
}
.toast .toast-header {
  color: var(--gray01) !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}
.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}

.info-checkbox {
  margin: 0;
  /* margin-top: 20px; */
  padding-top: 5px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}

// markDown
.description-mark {
  margin: 30px auto !important;
}
.description-mark.preview .github-markdown-body {
  padding: 0;
}
.description-mark h1,
.description-mark h2,
.description-mark h3,
.description-mark h4,
.description-mark h5,
.description-mark h6 {
  padding-bottom: 15px !important;
  border-bottom: 1px solid #dce6f0 !important;
  margin: 40px 0 20px 0;
  font-weight: 600;
}
.description-mark h1 {
  font-size: 2rem;
}
.description-mark h2 {
  font-size: 1.8rem;
}
.description-mark p {
  font-size: 14px;
  color: #81858e;
  line-height: 1.5;
  margin: 20px 0 !important;
  font-weight: normal;
}
.description-mark ul,
.description-mark ol {
  margin: 10px 0;
}
.description-mark li + li {
  margin-top: 10px;
}
.description-mark blockquote {
  padding: 0 1rem;
  margin-left: 0;
  color: #81858e;
  border-left: 0.3rem solid #ededf0;
}
.description-mark code {
  margin-top: 1rem;
  display: block;
  padding: 1rem;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 0.9rem;
  color: #567482;
  background-color: #f3f6fa;
  border-radius: 0.3rem;
}
.description-mark table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
  -webkit-overflow-scrolling: touch;
}
.description-mark table th,
.description-mark table td {
  padding: 0.5rem 1rem;
  border: 1px solid #ededf0;
}

// preview
.description-mark.preview h1,
.description-mark.preview h2 {
  font-size: 1.3rem !important;
}

.description-mark.preview * {
  font-size: 13px !important;
}
.v-md-textarea-editor textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border: none !important;
  outline: none !important;
  resize: none;
}
.v-md-editor {
  border: 1px solid #ddd;
  box-shadow: none !important;
}
</style>
