import { render, staticRenderFns } from "./DropDownSubItem.vue?vue&type=template&id=2749fa2f&scoped=true&"
import script from "./DropDownSubItem.vue?vue&type=script&setup=true&lang=js&"
export * from "./DropDownSubItem.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./DropDownSubItem.vue?vue&type=style&index=0&id=2749fa2f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2749fa2f",
  null
  
)

export default component.exports