import { render, staticRenderFns } from "./iconRewards.vue?vue&type=template&id=963a7e40&scoped=true&"
import script from "./iconRewards.vue?vue&type=script&setup=true&lang=js&"
export * from "./iconRewards.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./iconRewards.vue?vue&type=style&index=0&id=963a7e40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963a7e40",
  null
  
)

export default component.exports