<template>
  <div class="container-fluid p-0" id="app">
    <BaseView>
      <router-view v-if="showRouter"></router-view>
    </BaseView>
  </div>
</template>

<script>
export default {
  name: "app",
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    isIframe() {
      return window.self !== window.top
    },
    showRouter(){
      if(!this.isGreenn){
        return true;
      }
      if(this.$route.meta && this.$route.meta.sidebar === false){
        return true;
      }
    },
  },
  watch: {
    $route(to) {
      if (this.isIframe) {
        return
      }
      if (
        to.name === "Pipeline" ||
        to.name === "Login" ||
        to.name === "Login2" ||
        to.name === "LoginRdr" ||
        to.name === "verificar-email" ||
        to.name === "verificar-email-token" ||
        to.name === "verificar-email-token" ||
        to.name === "TemplaterEditorPage" ||
        to.name === "EditorPage" ||
        to.name === "Editor" ||
        to.name === "Integração"
      ) {
        window.$crisp?.push(["do", "chat:hide"]);
      } else {
        window.$crisp?.push(["do", "chat:show"]);
      }
      if (to.path === `/${Cookies.get("tenantSubdomain")}/selecionar-painel`) {
        Cookies.remove("tenantSubdomain");
        window.location.href =
          "/adm/selecionar-painel" + window.location.search;
      }
    },
  },
  methods:{
    setFavicon(url) {
      // Encontra o elemento de link do favicon existente, se houver
      let link = document.querySelector("link[rel*='icon']");

      // Se o elemento existir, remove-o
      if (link) {
        document.head.removeChild(link);
      }

      // Cria um novo elemento de link
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = url;

      // Adiciona o novo elemento de link ao head do documento
      document.head.appendChild(link);
    }
  },
  created() {
  if (process.env.BASE_URL_GREENN.includes(window.location.host)) {
    this.$store.dispatch('updateIsGreenn', true)
    this.$store.dispatch('updateNameSystem', 'Greenn Sales')
    document.title = 'Greenn Sales';
    this.setFavicon(window.location.origin + '/logo3.png')
  } else {
    this.$store.dispatch('updateIsGreenn', false)
    this.$store.dispatch('updateNameSystem', 'G Digital')
    document.title = 'G Digital';
    this.setFavicon(window.location.origin + '/logo2.ico')
  }
},
};
</script>

<style lang="scss">
// Para facilitar a visualização das opções selecionadas de multiselects múltiplos 
.fit-height {
  .multiselect__tags {
    height: unset !important;
    min-height: 50px !important;
    &:has(.multiselect__tag) {
      padding-bottom: 7px !important;
      padding-top: 16px !important;
      .multiselect__input {
        padding-top: 10px !important;
      }
    }
  }
  .multiselect__placeholder {
    margin-bottom: 0 !important;
    line-height: 1.2 !important;
  }
  .multiselect.multiselect--active::before {
    display: none !important;
  }
  .multiselect__tag {
    max-width: calc(100% - 10px) !important;
  }
}

.loading-btn {
  position: relative;
  &::after {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: inherit;
    background: linear-gradient(100deg, #ececec55 8%, #f5f5f588 18%, #ececec55 33%);
    background-size: 200% 100%;
    animation: shine 1s linear infinite;
    cursor: wait;
  }
}

.shine {
  background: linear-gradient(100deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shine 1s linear infinite;
  border-color: var(--white-medium) !important;
  border-radius: 10px;
  cursor: wait;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-moz-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-o-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.__blocked {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: grid;
    gap: 20px;
    background-color: #fff8;
    backdrop-filter: blur(10px);
    align-content: center;
    justify-items: center;
    position: absolute;
    z-index: 10;
    left: 0px;
    top: 0;
    color: var(--greenn-dark);
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    transition: all 0.3s;
    &:hover {
      backdrop-filter: blur(5px);
    }
    button {
      font-size: 15px;
      border-radius: 10px;
      height: 45px;
      padding: 0 15px;
      background-color: var(--greenn);
      color: white;
      border: none;
      transition: all 0.3s;
      font-weight: 500;
      &:hover {
        transform: scale(1.05);
      }
    }
}

.cke_dialog_body {
  border-radius: 10px;
  overflow: hidden;
  * {
    font-family: "Montserrat" !important;
  }
  .cke_dialog_title,
  .cke_dialog_footer {
    font-family: "Montserrat" !important;
    background-color: white !important;
    color: var(--gray01);
    font-weight: 500 !important;
    font-size: 16px !important;
    padding: 16px 8px 16px 16px;
    border-bottom-color: var(--white-medium) !important;
    border-top-color: var(--white-medium) !important;
    a {
      padding: 10px 15px !important;
    }
    a.cke_dialog_ui_button_ok {
      background-color: var(--greenn);
      &:hover {
        filter: brightness(0.8) !important;
      }
    }
  }
  .cke_dialog_ui_vbox table,
  .cke_dialog_ui_hbox table {
    border-radius: 10px;
    overflow: hidden;
  }
  .cke_dialog_contents_body {
    padding: 10px 35px !important;
  }
  .cke_dialog_ui_vbox_child div {
    border: none !important;
    border-radius: 5px !important;
  }
  .cke_dialog_ui_vbox_child {
    width: 74px !important;
  }
  .cke_dialog_body label,
  .cke_dialog_ui_labeled_label {
    display: none !important;
  }
  [title="Limpar"] {
    display: none !important;
  }
  input {
    border-radius: 5px !important;
    padding: 5px !important;
    font-size: 13px !important;
    height: auto !important;
    width: 74px !important;
  }
}
::selection {
  background: var(--greenn);
  color: #fff;
}
::-moz-selection {
  background: var(--greenn);
  color: #fff;
}
@import "./assets/scss/main.scss";
@import "./assets/variables.css";
:root {
  --escuro: #2e2f31 !important;
  --claro: #5b5e64 !important;
  --verde: #42b983 !important;
}
.broadcast-html .multiselect__option.multiselect__option--highlight span {
  color: var(--gray01) !important;
}
// #leads-data .daterangepicker td.active, .daterangepicker td.active:hover {
//     background-color: var(--greenn) !important;
// }
// #leads-data .daterangepicker td.in-range {
//     background-color: var(--greenn2) ;
//     border-color: transparent;
//     color: #000;
//     border-radius: 0;
// }
// #leads-data .daterangepicker td.off, #leads-data  .daterangepicker td.off.end-date, #leads-data .daterangepicker td.off.in-range, #leads-data .daterangepicker td.off.start-date {
//     background-color: #fff;
//     border-color: transparent !important;
//     color: #999;
// }
body {
  overflow: hidden;
  accent-color: var(--greenn);
}
input[type="date"]::-webkit-datetime-edit-year-field:focus,
input[type="date"]::-webkit-datetime-edit-month-field:focus,
input[type="date"]::-webkit-datetime-edit-day-field:focus {
  background-color: var(--greenn-transparent);
  border-radius: 3px;
}

.calendars {
  input, .monthselect {
    height: 40px !important;
    max-height: 40px !important;
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .monthselect {
    padding: 5px !important;
    text-align: center;
    background-color: transparent;
    height: 50px;
    margin-bottom: 0 !important;
  }
  .yearselect {
    margin-bottom: 0 !important;
    height: 50px;
    text-align: center;
  }
}

.fa-calendar {
  color: var(--greenn);
}
.btn-table img {
  max-width: fit-content !important;
}
.conteudo {
  .nav-tabs {
    border-bottom: 1px solid #e6e6e6;
  }
  .nav-tabs .nav-link {
    border: none !important;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--greenn);
    font-weight: 600;
  }
  .nav-item a {
    color: var(--gray01);
  }
}
.space {
  .btn-secondary:focus,
  .btn-secondary.focus {
    background-color: var(--greenn);
    border-color: #000;
  }
  .btn-secondary:hover {
    background-color: var(--greenn);
    border-color: #000;
  }
}
@media screen and (max-width: 500px) {
  .modal-content {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 400px) {
  .modal-content {
    margin-top: 75px !important;
  }
}
.b-sidebar > .b-sidebar-body::-webkit-scrollbar-thumb {
  background-color: #d4d8db;
  border-radius: 20px;
}
.b-sidebar > .b-sidebar-body::-webkit-scrollbar-track {
  background-color: transparent;
}
.b-sidebar > .b-sidebar-body::-webkit-scrollbar {
  width: 11px;
  background: transparent;
}
.lv-colorpicker-wrapper .lv-input__group .lv-input__field:hover {
  box-shadow: inset 0 0 0 1px var(--greenn) !important;
}
#LvColorpicker .lv-input__group .lv-input__field .lv-input__element {
  border-color: transparent !important;
}
.lv-colorpicker-wrapper {
  display: grid !important;
}
.lv-input__group .lv-input__field:focus-within {
  box-shadow: none !important;
}
.lv-input__field .lv-input__append {
  margin-right: 5px !important;
}
.lv-input__label {
  font-weight: 600 !important;
  // margin-bottom: 0px !important;
}
.SidebarEditor {
  .b-sidebar-outer {
    z-index: 12 !important;
  }
}
.VueCarousel-pagination {
  display: none !important;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}
body::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}

button#dropdown-lista__BV_toggle_,
button#dropdown-lista__BV_toggle_:focus {
  background: transparent;
  color: black;
  border: none;
  font-family: Montserrat;
  font-weight: 500;
  margin-top: -7px;
  box-shadow: none;
}

button#extras-btns__BV_toggle_,
button#extras-btns__BV_toggle_:focus {
  background: transparent;
  width: 59px;
  height: 50px;
  margin-right: 10px;
  border: none;
  margin-left: 2px;
  box-shadow: none;
  padding: 5px 13px !important;
}
#extras-btns {
  a.dropdown-item {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 14px !important;
  }
  // ul {
  // transform: translate3d(10px, 45px, 0px) !important;
  // }
}

.btn-broadcast {
  display: flex;
  width: 230px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none !important;
  font-weight: 600;
  height: 50px;
  padding: 0 15px !important;
  border-radius: 10px !important;
  font-size: 14px;

  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  background: transparent !important;
  color: var(--greenn) !important;
  border: 1px var(--greenn) solid !important;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:hover {
    transition: all 0.3s;
    transform: scale(1.05);
    background: var(--greenn) !important;
    color: #fff !important;
    svg {
      fill: #fff;
      transition: all 0.3s;
    }
  }
  &:focus {
    box-shadow: none;
  }
  svg {
    fill: var(--greenn);
    transition: all 0.3s;
  }
}
.btn-active-broadcast {
  background: var(--greenn) !important;
  color: #fff !important;
  svg {
    fill: #fff !important;
  }
}
#modal-list {
  .modal-content {
    padding: 0px !important;
    .modal-header {
      border: none !important;
      z-index: 99;
      padding: 25px 40px !important;
    }
  }

  .modal-body {
    margin-top: -60px !important;
    padding: 0px !important;
  }
}
.add-link-body {
  display: flex !important;
  align-items: baseline !important;
  justify-content: space-between !important;
  .add-link {
    font-size: 13px;
    margin-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
  .to-right {
    text-align: end;
    width: 100%;
  }
}

.add-link-body2 {
  display: flex !important;
  align-items: baseline !important;
  justify-content: none !important;
  .add-link {
    font-size: 13px;
    margin-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
  .to-right {
    text-align: end;
    width: 100%;
  }
}

#dropdown-lista {
  button#dropdown-lista__BV_toggle_,
  button#dropdown-lista__BV_toggle_:focus {
    background: #cccccc2f;
    border: 1px solid #ccc;
    transition: all 0.3s;
    &:hover {
      background: #cccccc3d;
    }
  }
  .dropdown-lista__BV_toggle_ {
    .dropleft .dropdown-toggle::before {
      transform: rotate(270deg) !important;
    }
  }
  .dropdown-menu.show {
    top: 0px !important;
    // left: -40px !important;
  }
  .dropdown-menu.show a {
    padding: 8px 20px !important;
  }
}
li.red-item a {
  color: red;
}

.w-100 {
  width: 100% !important;
}

.multiselect__option--highlight:after {
  background: var(--greenn) !important;
}
#filterLeads button,
#filterLeads button:focus {
  background: transparent;
  border: 1px var(--bluee) solid !important;
  background: rgba(33, 51, 210, 0.1);
  height: 60px !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  border-radius: 10px;
  font-size: 12px;
}
.cke_notification_warning{
    display: none;
  }
#filter-item {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  font-weight: bold !important;
}
#funilDrop button,
#funilDrop:focus {
  background-color: var(--greenn) !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 600;
  padding: 0 42px !important;
  height: 60px !important;
}
#funilDrop ul {
  padding: 0;
}
#textarea-tag {
  overflow-y: hidden !important;
  height: 150px !important;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem var(--greenn2) !important;
  box-shadow: 0 0 0 0.2rem var(--greenn2) !important;
}

.no-border,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none !important;
}

// #itemFunil{
//   font-weight: 600 !important;
//   text-align: center;
// }
// #itemFunil:hover{
//   border-left: 4px solid var(--greenn);
// }

.visualizar-lead {
  #dropPage button,
  #dropPage button:focus {
    background: transparent !important;
    border: none !important;
    height: 50px !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

#dropPage button,
#dropPage button:focus {
  background: transparent !important;
  border: none !important;
  height: 22px !important;
  outline: none !important;
  box-shadow: none !important;
  position: relative !important;
  top: -18px !important;
  right: -11px !important;
}
#cardFunil #dropPage button {
  margin-top: 0px !important;
}
#popup #dropPage button,
#dropPage {
  margin-top: -13px !important;
}
#site-body #dropPage {
  margin-top: -75px !important;
  height: 0 !important;
  margin-right: -10px !important;
}
#pagina-body #dropPage {
  margin-top: -35px !important;
  height: 0 !important;
  margin-right: -10px !important;
}
#dropForm button,
#dropForm button:focus {
  background: transparent !important;
  border: none !important;
  height: 0px !important;
  padding: 15px 10px !important;
  outline: none !important;
  box-shadow: none !important;
}
#dropView button,
#dropView button:hover {
  background: transparent !important;
  padding: 14px 4px;
  border-radius: 50%;
  color: transparent !important;
  border: none !important;
  height: 0px !important;
  outline: none !important;
}
#dropView img {
  margin: -2px 0;
}
#dropView button:hover {
  box-shadow: inset 0 0 0 12px #ffffff1f;
}

#dropContainer button,
#dropContainer button:hover {
  background: transparent !important;
  padding: 14px 4px;
  border-radius: 50%;
  color: transparent !important;
  border: none !important;
  height: 0px !important;
  outline: none !important;
}
#dropContainer img {
  margin: -2px 0;
}
#dropContainer button:hover {
  box-shadow: inset 0 0 0 12px #2929291f;
}

.multiselect__tags {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

#card-formulario .multiselect__tags {
  overflow: hidden;
}

#select-pixel .multiselect .multiselect__tags {
  overflow-y: auto !important;
}
#multselect-lead .multiselect__tags {
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.custom-file-label {
  border: 1px solid #d6d6d6 !important;
  padding: 15px !important;
  color: var(--gray01) !important;
  width: 100% !important;
  outline: none !important;
  border-radius: 4px !important;
  font-weight: 600;
  transition: 0.2s ease !important;
  font-size: 14px !important;
}

.progress-bar.progress-bar-striped.bg-danger {
  background: var(--red) !important;
}
.progress-bar.progress-bar-striped {
  background: var(--greenn) !important;
}
.lead-unico .reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 13px 22px !important;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  border-radius: 0px 10px 10px 0px !important;
  height: 50px !important;
}
.lead-unico .reportrange-text[data-v-1ebd09d2]:hover {
  border: none;
}
.lead-unico .daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background-color: transparent !important;
  text-align: center;
  width: 100px;
  height: 33px !important;
  padding: 2px;
  outline: 0;
  font-size: 12px;
  padding: 5px !important;
  margin: 0 5px !important;
}
.graphic .apexcharts-legend {
  position: static !important;
}

img.attachment-post-thumbnail.size-post-thumbnail.wp-post-image {
  object-fit: contain !important;
  height: auto !important;
  border-radius: 3px;
}
.thumb-template img {
  object-fit: contain !important;
  height: auto !important;
}
.lead-unico .seller {
  width: 310px !important;
}
.drop-sellers-teste .dropdown-menu.show {
  height: 400px !important;
  width: 290px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
#visualizar-lead-tab #dropPage {
  margin-top: -25px !important;
}
.drop-sellers {
  width: 44px;
}

.country-selector__country-flag {
  top: 18px !important;
  left: 13px !important;
  cursor: pointer;
}
.country-selector__toggle {
  top: calc(50% - 7px) !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--white-dark);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 2px;
  background: transparent;
}

.country-selector__list {
  border-radius: 0 10px 10px !important;
}
.drop-sellers .dropdown-menu.show {
  // height: 400px !important;
  max-height: max-content !important;
  height: 200px !important;
  overflow: auto !important;
  padding: 0px !important;
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    // border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
#visualizar-lead-tab .country-selector__country-flag {
  top: 18px !important;
  left: 13px !important;
  cursor: pointer;
}
.country-selector.has-value .country-selector__input {
  padding-left: 43px !important;
  border-radius: 10px 0 0 10px !important;
}
#visualizar-lead-tab #__BVID__195__outer_ {
  padding: 1px !important;
}
#visualizar-lead-tab .b-form-spinbutton.flex-column {
  height: 112px !important;
}
#visualizar-lead-tab .b-calendar .b-calendar-grid {
  height: auto !important;
}
#visualizar-lead-tab .b-form-btn-label-control.form-control > .form-control {
  padding: 1px !important;
  margin-top: 3px !important;
  border: none !important;
}

#visualizar-lead-tab .multiselect__single {
  font-weight: 500 !important;
  background: transparent;
}

#input-modal .multiselect__stags {
  padding: 7px 40px 15px 20px !important;
}

#visualizar-lead header,
#visualizar-lead footer {
  display: none !important;
}

#formattedNumber {
  .input-tel__label {
    font-size: 11px !important;
    font-weight: 400 !important;
    top: 2px !important;
    left: 20px !important;
  }
}

.tags {
  .multiselect__tags {
    overflow-y: auto;
  }
}

.anima_search {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 4s ease-in-out forwards;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

#paginate-leads {
  .multiselect__tags {
    width: 170px !important;
  }
}
#product-select {
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: #e7e7e7 !important;
  }
}
#select-type-pixel-edit,
#select-type-pixel {
  .multiselect__tags,
  .multiselect__single,
  .multiselect__input {
    background-color: transparent !important;
  }
}
// Todos os multiselect -->
.multiselect__select {
  // z-index: 10;
  transform: rotate(180deg) !important;

  // &:hover {
  // .multiselect__tags {
  // border: 0.5px solid var(--greenn) !important;
  // }
  // }
}
// .multiselect__tags {
//   transition: all 0.3s;
//   &:hover {
//     border: 0.5px solid var(--greenn) !important;
//   }
// }
.multiselect.multiselect--active {
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: linear-gradient(135deg, #fff 50%, transparent 50%);
    border-radius: 3px;
    z-index: 999999;
    position: absolute;
    right: 13px;
    transform: rotate(45deg);
    top: 55px;
    border-top: 1px solid var(--white-medium);
    border-left: 1px solid var(--white-medium);
    display: block;
  }
  .multiselect__select {
    transform: rotate(0deg) !important;
  }
}
.multiselect__select::before {
  color: var(--greenn) !important;
  border-color: var(--greenn) transparent transparent;
  &::hover {
    color: #3483fa !important;
  }
}
.multiselect__option--selected {
  background: var(--white-light) !important;
  border-left: 3px solid var(--greenn) !important;
  font-weight: 400;
  font-size: 14px;
}
.multiselect__input,
.multiselect__single,
.multiselect__tags {
  font-size: 14px !important;
  // padding: 9px !important;
  font-weight: 400;
}
.multiselect__input {
  transition: none !important;
}
.multiselect__placeholder {
  padding-top: 5px !important;
}
.multiselect__option {
  border-left: 3px solid transparent;
}
.multiselect__option--highlight {
  background: var(--white-light) !important;
  font-weight: 400;
  font-size: 14px;
  border-left: 3px solid var(--greenn);
}
.multiselect__option--highlight:after {
  background: var(--white-light) !important;
  font-weight: 400;
  font-size: 14px;
}
.multiselect__content-wrapper {
  // width: 270px !important;
  border: 1px solid var(--white-medium) !important;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  // padding-top: 12px;
}
.multiselect__content-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
.multiselect__content-wrapper::-webkit-scrollbar {
  display: none;
  background: transparent;
}
.multiselect__option.multiselect__option--selected {
  border-left: 3px solid var(--greenn) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  &::after {
    color: var(--gray04) !important;
  }
}
.multiselect__option.multiselect__option--highlight {
  background: var(--white-light) !important;
  &:after {
    background: var(--white-light) !important;
    color: var(--gray04) !important;
  }
}
.multiselect__option.multiselect__option--disabled span{
    color: var(--white-dark) !important;
}
// Multiselect Lead Unico -->
//  MULTIPLE
.lead-unico .multiselect__tag {
  background: var(--white) !important;
  height: 25px !important;
  border: 1px solid var(--gray01) !important;
  border-radius: 50px !important;
  background-color: white !important;
  text-align: center !important;
  padding: 4px 18px 4px 6px !important;
  margin: 5px !important;
  span {
    color: var(--gray01) !important;
    font-weight: 400;
    font-size: 14px;
  }
}

.lead-unico span.multiselect__tag span {
  color: var(--gray01) !important;
  font-weight: 400;
  font-size: 14px;
}
.lead-unico .tags-list .multiselect__tags {
  overflow: inherit;
  border: none !important;
  height: auto !important;
}
.lead-unico .multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: transparent !important;
  color: #000 !important;
}
.lead-unico .multiselect--active {
  z-index: 10;
}
.lead-unico .multiselect__tag-icon:after {
  color: var(--red) !important;
}
.lead-unico .seller .multiselect__content-wrapper {
  // width: initial !important;
  overflow-x: hidden !important;
}
.lead-unico {
  #multiple {
    .multiselect__option {
      font-weight: 400 !important;
      font-size: 14px !important;
    }
    .multiselect__option.multiselect__option--selected {
      border-left: 3px solid var(--greenn) !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      &::after {
        color: var(--gray04) !important;
      }
    }
    .multiselect__option.multiselect__option--highlight {
      background: var(--white-light) !important;
      &:after {
        background: var(--white-light) !important;
        color: var(--gray04) !important;
      }
    }
  }
}
// NOT MULTIPLE
.lead-unico {
  #not_multiple {
    position: relative;
    .multiselect__select {
      // z-index: 10;
      transform: rotate(180deg) !important;
    }
    .multiselect.multiselect--active {
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        background: linear-gradient(135deg, #fff 50%, transparent 50%);
        border-radius: 3px;
        z-index: 999999;
        position: absolute;
        right: 13px;
        transform: rotate(45deg);
        top: 55px;
        border-top: 1px solid var(--white-medium);
        border-left: 1px solid var(--white-medium);
        display: block;
      }
      .multiselect__select {
        transform: rotate(0deg) !important;
      }
    }
    .multiselect__select::before {
      color: var(--greenn);
      border-color: var(--greenn) transparent transparent;
    }

    .multiselect__option--selected {
      background: var(--white-light) !important;
      border-left: 3px solid var(--greenn) !important;
      font-weight: 400;
      font-size: 14px;
    }
    .multiselect__input,
    .multiselect__single,
    .multiselect__tags {
      font-size: 14px !important;
      // padding: 9px !important;
      font-weight: 400;
    }
    .multiselect__option--highlight {
      background: var(--white-light) !important;
      font-weight: 400;
      font-size: 14px;
    }
    .multiselect__option--highlight:after {
      background: var(--white-light) !important;
      font-weight: 400;
      font-size: 14px;
    }
    .multiselect__content-wrapper {
      // width: 270px !important;
      border: 1px solid var(--white-medium) !important;
      border-radius: 6px;
      border: none;
      font-weight: 400;
      font-size: 14px;
      margin-top: 12px;
      // padding-top: 12px;
    }
    .multiselect__content-wrapper::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .multiselect__content-wrapper::-webkit-scrollbar {
      display: none;
      background: transparent;
    }
  }
}
// Multiselect Pipeline -->
.select-pipe {
  .multiselect__select {
    z-index: 10;
    transform: rotate(180deg) !important;
  }
  .multiselect__select::before {
    color: var(--greenn);
    border-color: var(--greenn) transparent transparent;
  }
  .multiselect__option--selected {
    background: var(--white-light) !important;
    border-left: 3px solid var(--greenn);
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__input,
  .multiselect__single,
  .multiselect__tags {
    font-size: 14px !important;
    // padding: 9px !important;
    font-weight: 400;
  }
  .multiselect__option--highlight {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__option--highlight:after {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__content-wrapper {
    width: 270px !important;
    border: 1px solid var(--white-medium) !important;
    border-radius: 6px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    margin-top: 12px;
    // padding-top: 12px;
  }
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: linear-gradient(135deg, #fff 50%, transparent 50%);
    border-radius: 3px;
    z-index: 999999;
    position: absolute;
    right: 13px;
    transform: rotate(45deg);
    top: 55px;
    border-top: 1px solid var(--white-medium);
    border-left: 1px solid var(--white-medium);
    display: none;
  }
  &.multiselect--active {
    &::before {
      display: block;
    }
    .multiselect__select {
      transform: rotate(0deg) !important;
    }
  }
  .multiselect__content-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .multiselect__content-wrapper::-webkit-scrollbar {
    display: none;
    background: transparent;
  }
}
.container-editor {
  .multiselect__select::before {
    color: var(--greenn);
    border-color: var(--greenn) transparent transparent;
  }
  .multiselect__option--selected {
    background: var(--white-light) !important;
    border-left: 3px solid var(--greenn) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__input,
  .multiselect__single,
  .multiselect__tags {
    font-size: 14px !important;
    // padding: 9px !important;
    font-weight: 400;
  }
  .multiselect__option--highlight {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__option--highlight:after {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__content-wrapper {
    border: 1px solid var(--white-medium) !important;
    border-radius: 6px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    // margin-top: 12px;
    // padding-top: 12px;
  }
  // .multiselect {
  //   &::before {
  //     content: "";
  //     width: 15px;
  //     height: 15px;
  //     background: linear-gradient(135deg, #fff 50%, transparent 50%);
  //     border-radius: 3px;
  //     z-index: 999999;
  //     position: absolute;
  //     right: 13px;
  //     transform: rotate(45deg);
  //     top: 54px;
  //     border-top: 1px solid var(--white-medium);
  //     border-left: 1px solid var(--white-medium);
  //     display: none;
  //   }
  //   &.multiselect--active {
  //     &::before {
  //       display: block;
  //     }
  //     .multiselect__select {
  //       transform: rotate(0deg) !important;
  //     }
  //   }
  // }
  .multiselect__content-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .multiselect__content-wrapper::-webkit-scrollbar {
    display: none;
    background: transparent;
  }
}
#select-type-pixel-edit .multiselect__tags {
  background-color: #efefef4d !important;
}

#leads-filters .modal-content {
  overflow: hidden !important;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: transparent !important;
  color: #a6a6a6;
}
.multiselect--disabled {
  border-radius: 10px !important;
  // border: 0.5px solid var(--white-medium);
  .multiselect__tags {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #efefef4d;
      z-index: 90;
      left: 0px;
      top: 0;
    }
  }
}

.select-pipeMobile {
  .multiselect__select::before {
    color: var(--greenn);
    border-color: var(--greenn) transparent transparent;
  }
  .multiselect__tags {
    border: none !important;
    float: right !important;
    width: 195px !important;
    // padding: 15px 30px !important;
  }
  .multiselect__option--selected {
    background: var(--white-light) !important;
    border-left: 3px solid var(--greenn);
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__input {
    transition: none !important;
  }
  .multiselect__option--highlight {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__option--highlight:after {
    background: var(--white-light) !important;
    font-weight: 400;
    font-size: 14px;
  }
  .multiselect__content-wrapper {
    border-radius: 6px;
    width: 195px !important;
    float: inherit;
    right: 10px;
    top: 35px;
  }
  .multiselect__content-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .multiselect__content-wrapper::-webkit-scrollbar {
    display: none;
    background: transparent;
  }
}
.select-pipe {
  border: 0 !important;
  font-weight: 600 !important;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L3.5 3.5L6 1' stroke='%2381858E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    right 0.75rem center/8px 10px no-repeat !important;
  width: 270px !important;
}
.select-pipe:focus {
  box-shadow: none !important;
}
.select-camp {
  border: 0 !important;
  font-weight: 600 !important;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L3.5 3.5L6 1' stroke='%2381858E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    right 0.75rem center/8px 10px no-repeat !important;
  width: 100% !important;
}
.select-camp:focus {
  box-shadow: none !important;
}
#sidebar-config-form .b-sidebar {
  width: 450px !important;
}
.inputs-list {
  li {
    list-style: none !important;
  }
  .checkbox {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    height: 0px !important;
    padding: 20px 0px 15px 0px !important;
    label {
      margin-bottom: 10px !important;
    }
  }
  .radio {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    height: 0px !important;
    padding: 20px 0px 15px 0px !important;
    label {
      margin-bottom: 12px !important;
    }
  }
}
// #leads-data .daterangepicker .yearselect {
//   width: 100px !important;
// }
.content-email-total iframe {
  min-height: 500px !important;
}
#Traffic {
  .btn-primary {
    border: none !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 9999px !important;
    background-color: var(--greenn2) !important;
    padding: 2px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 5px !important;

    transition: all 0.3s;
    &:hover {
      transform: scale(0.95);
    }
    &:focus {
      border: none !important;
      border-color: transparent !important;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--greenn) !important;
  background-color: var(--greenn) !important;
  box-shadow: none !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--greenn2) !important;
  border-color: var(--greenn2) !important;
}

#inscricao-div {
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    margin-left: 25px;
    max-width: 95%;
  }
}
.mail-group iframe {
  height: 500px !important;
}

div#sidebar-ActionStepsCampanha {
  width: 450px;
  padding-left: 42px;
  padding-top: 20px;
}

#sidebar-ActionStepsCampanha .close {
  display: none !important;
}
// CAMPAANHAS
// multiselect --->>
.content-tags .multiselect__tags {
  min-height: 55px;
  padding: 12px 40px 0 8px;
}
.content-tags .multiselect__select {
  top: 8px;
}
.content-tags.blue .multiselect__option--highlight {
  background: #3483fa;
}
.content-tags .multiselect__single {
  color: #00a650;
  background: #00a6501a;
  padding: 5px;
  text-align: center;
  font-weight: 600;
}
.content-tags.blue .multiselect__single {
  color: #3483fa;
  background: #3483fa1a;
  padding: 5px;
  text-align: center;
  font-weight: 600;
}
// HeaderCampanha switch --->>
.fluxo-livre .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--bluee);
  background-color: var(--bluee);
}
.fluxo-livre
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--bluee);
  background-color: #fff;
}
.fluxo-livre .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--blue2) 40;
}
// Campanha Lista Unica -->>
// .unic_list {
//   #Table-body-container {
//     div {
//       z-index: 99999;
//     }
//   }
// }
// Card Campanha MULTISELECT --->>
#card-formulário {
  .multiselect__option--highlight:after {
    background: blue !important;
  }
}
::selection {
  background: var(--greenn);
  color: #fff;
}
::-moz-selection {
  background: var(--greenn);
  color: #fff;
}
// Card Campanha switch --->>
.container-sequence
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--bluee);
  background-color: var(--bluee);
}
.container-sequence
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--bluee);
  background-color: #fff;
}
.container-sequence
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--blue2) 40;
}
// MODAL COMPANY
// #ModalCompany___BV_modal_content_ {
//   width: 70%;
//   height: 90%;
// }
#ModalCompany {
  .modal-company {
    width: 100% !important;
    //   margin: 1.75rem auto !important;
  }
  padding: 0 50px 0 50px !important;
}
#visualizar-lead {
  .modal-dialog {
    max-width: 95vw !important;
  }
  .modal-content {
    border-radius: 10px;
    height: 92vh !important;
    max-height: 92vh !important;
    overflow: hidden;
    width: 95vw !important;
    &::-webkit-scrollbar-thumb {
      background-color: var(--white-dark);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background: white;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
  }
}
// Sidebar Menu Horizontal
.menu-actions {
  .dropdown-menu {
    padding: 5px;
    background: rgba(0, 0, 0, 0.8);
    top: 35px !important;
    left: -25px !important;
    .dropdown-item {
      padding: 0px !important;
      color: #fff;
      font-weight: 500;
      font-size: 13px !important;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background: transparent !important;
      }
    }
  }
}
.modal-company {
  max-width: 100vw !important;
  // height: 100vh !important;
  margin: 1.75rem auto !important;
}
#dropdown-lista .custom-control-label {
  cursor: pointer !important;
}

#ModalSavePage {
  overflow: hidden !important;
}
#ModalSavePage .modal-footer,
#ModalSavePage .modal-header {
  border: none !important;
}

#Modal-Terms {
  .modal-dialog {
    max-width: 95vw !important;
  }
}

#Modal-Terms .modal-footer,
#Modal-Terms .modal-header {
  border: none !important;
}
#Modal-Lead-Message {
  .modal-content,
  .modal-body {
    padding: 0px !important;
    border-radius: 10px;
  }

  .modal-header,
  .modal-footer {
    // display: none !important;
    border: none !important;
    padding: 0px !important;
    h5 {
      display: none !important;
    }

    .close {
      position: absolute !important;
      z-index: 999999 !important;
      right: 30px !important;
      top: 25px !important;
    }
  }
}
#Modal-Lead-Message .modal-footer {
  display: none !important;
}
.input-acoes {
  .multiselect__tags {
    width: 300px !important;
    padding: 0 !important;
    overflow: hidden !important;
  }
  .multiselect__single {
    margin-top: -7px !important;
  }
  .multiselect__placeholder {
    margin-top: -3px !important;
    padding: 0 !important;
    display: block !important;
  }
  .multiselect__select {
    left: 260px !important;
  }
  #selectect.multiselect__content-wrapper {
    max-width: 94% !important;
  }
}
#make_form .dragArea select {
  padding-top: 14px !important;
  height: 50px !important;
}
#make_form .dragArea {
  max-width: 100% !important;
  word-wrap: break-word !important;
}
#make_form .dragArea input[type="file"] {
  padding: 8px 20px !important;
}
#make_form textarea {
  resize: none;
  overflow: hidden;
}
#Modal-Upload-Title .modal-footer,
#Modal-Upload-Title .modal-header {
  border: none !important;
}

#Modal-traffic .modal-body {
  height: 390px !important;
  @media (max-width: 768px) {
    height: 680px !important;
  }
}
#Modal-traffic .modal-footer {
  margin-top: 30px !important;
}

#Modal-traffic .modal-footer,
#Modal-traffic .modal-header {
  border: none !important;
}

#Modal-traffic .multiselect__input,
#Modal-traffic .multiselect__single {
  font-size: 14px !important;
}

#Modal-EditTraffic .modal-body {
  height: 390px !important;
}
#Modal-EditTraffic .modal-footer {
  margin-top: 30px !important;
}

#Modal-EditTraffic .modal-footer,
#Modal-EditTraffic .modal-header {
  border: none !important;
}

#Modal-EditTraffic .multiselect__input,
#Modal-EditTraffic .multiselect__single {
  font-size: 14px !important;
}

#Modal-Edit .modal-footer,
#Modal-Edit .modal-header {
  border: none !important;
}

#product-multiselect .multiselect__content-wrapper {
  top: 51px !important;
  z-index: 10 !important;
}

.progress-bar {
  background-color: var(--greenn) !important;
}

.grid-ativitie {
  input {
    height: auto !important;
  }
}
.daterangepicker .ranges {
  width: 100% !important;
}
.daterangepicker {
  border-radius: 10px !important;
  border-color: var(--white-medium) !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: var(--greenn) !important;
}
.daterangepicker .drp-buttons {
  border-color: var(--white-medium) !important;
}

.daterangepicker .ranges li {
  border-radius: 7px;
  transition: all 0.3s;
}

.daterangepicker tr td.in-range {
  background-color: var(--greenn-transparent);
}

.daterangepicker .btn-primary,
.daterangepicker .btn-success {
  background-color: var(--greenn) !important;
  font-weight: 500 !important;
  border-radius: 7px;
  padding: 5px 10px !important;
}

.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: transparent !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--greenn) !important;
}

.daterangepicker .ranges li.active {
  background-color: var(--greenn) !important;
}

.daterangepicker .btn-secondary {
  color: var(--gray01) !important;
  background: #f7f7f7 !important;
  font-weight: 500 !important;
  border-radius: 7px;
  font-size: 14px !important;
  padding: 5px 10px !important;
}
.multiselect__tags {
  border: 1px var(--greenn) solid !important;
  min-height: 40px !important;
  display: block !important;
  padding: 8px 40px 0 8px !important;
  border-radius: 10px !important;
  background: #fff !important;
}

.container-sequence .multiselect__single {
  padding: 0 !important;
  height: auto !important;
  background: transparent;
  border: none !important;
  font-size: 14px !important;
  color: black !important;
}

.container-sequence .multiselect__option {
  font-size: 14px !important;
  color: black !important;
}

.custom-control-label::before {
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.date-range .yearselect {
  padding: 15px 14px !important;
}
.eventos-conteudo-container {
  #dropPage button,
  #dropPage button:focus {
    top: 8px !important;
  }
}

.wizard2 .multiselect__tags {
  width: 260px !important;
}
#lead-unic {
  .TabHeader .nav-item a,
  .TabProduct .nav-item a,
  .TabVitrine .nav-item a {
    padding: 15px 33px !important;
  }
}

#Modal-simulator .modal-content {
  @media (max-width: 768px) {
    padding: 20px 15px !important;
  }
}

.grid-graph {
  @media (max-width: 768px) {
    zoom: 0.9 !important;
  }
}

#add-lead .modal-content {
  height: 595px !important;
}

.input-tel.has-hint .input-tel__label {
  opacity: 0 !important;
}

#MoverLead .modal-content {
  height: 500px !important;
}
#MoverLeadCtrl .modal-content {
  height: 570px !important;
}
@media (max-width: 500px) {
  .daterangepicker .calendars-container {
    display: grid !important;
    grid-template-columns: 1fr !important;
    place-items: center !important;
  }
  .daterangepicker.opensleft[data-v-1ebd09d2] {
    right: -40px !important;
    left: auto !important;
  }
}
@media (max-width: 420px) {
  .daterangepicker.opensleft[data-v-1ebd09d2] {
    right: -30px !important;
    left: auto !important;
  }
}
@media (max-width: 390px) {
  .daterangepicker.opensleft[data-v-1ebd09d2] {
    right: -5px !important;
    left: auto !important;
  }
}

#ModalEditarSeller {
  .modal-body {
    overflow: auto;
  }
  .modal-content {
    height: 750px !important;
  }
}

#modal-preview {
  .modal-header {
    border: none !important;
  }
  .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#Modal-simulator {
  .modal-content {
    // height: 500px !important;
  }
}

#Modal-simulator .multiselect__content-wrapper {
  overflow: auto;
  max-height: 215px !important;

  & .multiselect__content {
    max-height: 215px;
    overflow-y: auto;
  }

  & .multiselect__content::-webkit-scrollbar {
    width: 4px;
  }

  & .multiselect__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  & .multiselect__content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }

  & .multiselect__content {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5);
  }
}

.notification-pipeline,
.notification-campaign,
.notification-editor {
  position: relative;
}
// Notificação dentro do pipeline -->>
.notification-pipeline {
  .svg-filter {
    margin: 0 !important;
    filter: none !important;
    stroke: black;

    path {
      stroke: black;
      &:hover {
        stroke: black;
      }
    }
  }
  .box-notificacoes {
    .text-notfy {
      display: none;
    }
  }
  .fa-bell {
    color: black;
  }
  button#notification_button {
    left: inherit;
  }
  .arrow_box_down:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 18px;
    margin-left: 249px;
    top: -34px;
    transform: rotate(180deg);
  }
  .arrow_box_down:before {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #e4e4e4;
    border-width: 18px;
    margin-left: 249px;
    top: -36px;
    transform: rotate(180deg);
  }
}
// Notificação dentro das campanhas -->>
.notification-campaign {
  .svg-filter {
    position: inherit !important;
    margin: 0 !important;
    filter: none !important;
    stroke: black;

    path {
      stroke: black;
      &:hover {
        stroke: black;
      }
    }
  }

  .box-notificacoes {
    cursor: pointer;
    .text-notfy {
      display: none;
    }
  }
  .fa-bell {
    color: black;
  }
  button#notification_button {
    left: inherit;
  }
  .arrow_box_down:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 18px;
    margin-left: 249px;
    top: -34px;
    transform: rotate(180deg);
  }
  .arrow_box_down:before {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #e4e4e4;
    border-width: 18px;
    margin-left: 249px;
    top: -36px;
    transform: rotate(180deg);
  }
}
// Notificação dentro da side -->>
.box-notificacoes {
  .svg-filter,
  .text-notfy {
    transition: all 0.3s ease;
  }
  &:hover {
    .svg-filter {
      filter: invert(0) !important;
    }
    .text-notfy {
      color:  var(--greenn) !important;
    }
  }
}
#Modal-Templates {
  .modal-content {
    overflow: hidden !important;
  }
  .modal-footer {
    display: none !important;
  }
  .modal-header {
    border: none !important;
    padding: 10px 0px 0px 0px !important;
  }
  .modal-body {
    overflow-y: auto !important;
  }
}

.dropListUser {
  #dropPage button {
    top: 0 !important;
    right: 0 !important;
    padding-right: 15px;
  }
}

.tabListUser {
  .nav-link {
    padding: 15px 70px 15px 0px !important;
  }
}

#relatory {
  #pizzaGraph .apexcharts-tooltip span {
    color: white !important;
    font-weight: 600 !important;
  }
  .apexcharts-menu {
    width: 115px !important;
    .apexcharts-menu-item {
      font-size: 12px;
      color: #81858e !important;
    }
  }

  .multiselect--active {
    z-index: 10;
  }
}


</style>
