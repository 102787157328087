<template>
  <BaseModal
    name="verifySubdomain"
    id="verifySubdomain"
    idModal="verifySubdomain"
    size="lg"
    :title="'Bem vindo a ' + nameSystem + '!'"
    no-close-on-backdrop
    :hideHeader="true"
    :noBackdropClose="true"
    :noEscClose="true"
    @shown="openModal()"
  >
    <div class="welcome-banner">Bem vindo {{ isGreenn ? 'ao Greenn Sales' : 'a G Digital' }}!</div>
    <div class="container-flex">
      <div class="content-slider">
        <div :style=" step > 1 ? 'transform: translateX(-100%); ' : ''">
          <div class="mb-4">
            <p style="text-align: center;">Ficamos muito felizes em te receber! Siga os passos e faça as configurações inicias.</p>
          </div>
          <div>
            <label for="">Nome do Painel</label>
            <input
              type="text"
              v-model="name_pannel"
              class="w-100"
              :placeholder="'ex: '+ nameSystem"
            />
            <label for="subdomain">Subdominio do Painel</label>
              <input
                id="subdomain"
                type="text"
                v-model="subdomain"
                class="w-100"
                style="margin-bottom: 20px;"
                placeholder="ex: gdigital"
                autocomplete="off"
                @input.prevent
              />
            <div>
              <span class="validations" v-for="item in validationsSubdomain" :key="item.step" :style="item.validated ? 'color: var(--greenn);' : ''">
                <span>{{ item.validated ? '✓' : '✖' }}</span> {{ item.step }}
              </span>
            </div>
            <span class="preview-domain">Suas páginas ficarão no domínio: <span style="font-weight: 500;">https://<span style="color: green;">{{ subdomain ? subdomain.toLocaleLowerCase() : 'seu_dominio' }}</span>.gdigital.com.br </span></span>
          </div>
        </div>
        <div :style=" step > 2 ? 'transform: translateX(-100%); ' : step < 2 ? 'transform: translateX(100%);' : ''">
          <div class="mb-4">
            <p style="text-align: center;">Crie uma nova senha para a sua conta.</p>
          </div>
          <div>
            <label for="">Nova senha</label>
            <div class="passwork-wrapper">
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="newPassword"
                class="w-100"
                autocomplete="off"
              />
              <img
                @click="showPassword = !showPassword"
                v-if="showPassword"
                src="@/assets/editor/eye.svg"
                alt="Esconder senha"
              />
              <img
                @click="showPassword = !showPassword"
                v-else
                src="@/assets/editor/eye-off.svg"
                alt="Mostrar senha"
              />
            </div>
            <span class="validations" v-for="item in validations" :key="item.step" :style="item.validated ? 'color: var(--greenn);' : ''">
              <span>{{ item.validated ? '✓' : '✖' }}</span> {{ item.step }}
            </span>
          </div>
        </div>        
        <div :style=" step > 3 ? 'transform: translateX(-100%); ' : step < 3 ? 'transform: translateX(100%);' : ''" v-if="hasStrategic">
          <div class="mb-4">
            <p style="text-align: center;">Crie seu primeiro planejamento estratégico para definir suas metas em um ano.</p>
          </div>
          <div class="container-all-marketing">
            <!-- Quantos quero faturar ?  -->
            <div class="container-marketing" @mouseenter="inputHover = 'profit'">
              <div class="container-label">
                <span id="investment" class="name">Quanto quer faturar? {{ show_profit ? 'R$ ' : '' }}</span>
                <span
                  v-show="!show_profit"
                  id="investment"
                  class="value"
                  @click="(show_profit = true), validShow('profit')"
                  >R$ {{ profit | formatMoney }}</span
                >
                <input
                  v-show="show_profit"
                  id="profit"
                  v-model="profit"
                  class="reset-money"
                  @blur="show_profit = false"
                  min="0"
                  max="500000"
                  type="number"
                />
              </div>
              <input
                ref="profit2"
                id="custom-range"
                type="range"
                min="0"
                max="500000"
                data-max="500000"
                step="1000"
                v-model="profit"
              />
            </div>
            <!-- Preço do produto  -->
            <div class="container-marketing" @mouseenter="inputHover = 'product'">
              <div class="container-label">
                <span id="investment" class="name">Preço do produto {{ show_product ? 'R$ ' : '' }}</span>
                <span
                  v-show="!show_product"
                  id="investment"
                  class="value"
                  @click="(show_product = true), validShow('product')"
                  >R$ {{ product | formatMoney }}</span
                >
                <input
                  v-show="show_product"
                  id="product"
                  v-model="product"
                  class="reset-money"
                  @blur="show_product = false"
                  min="0"
                  max="15000"
                  type="number"
                />
              </div>
              <input
                ref="product2"
                id="custom-range"
                type="range"
                min="0"
                max="15000"
                step="100"
                data-max="15000"
                v-model="product"
              />
            </div>
            <!-- A cada quantos leads sai uma venda?  -->
            <div class="container-marketing" @mouseenter="inputHover = 'leads'">
              <div class="container-label">
                <span id="investment" class="name"
                  >Razão de lead por vendas </span
                >
                <span
                  v-show="!show_leads"
                  id="investment"
                  class="value"
                  @click="(show_leads = true), validShow('leads')"
                  >{{ leads | formatNumber }}</span
                >
                <input
                  v-show="show_leads"
                  id="leads"
                  v-model="leads"
                  class="reset-money"
                  @blur="show_leads = false"
                  min="1"
                  max="50"
                  type="number"
                />
              </div>
              <input
                ref="leads2"
                id="custom-range"
                type="range"
                min="1"
                max="50"
                step="1"
                data-max="50"
                v-model="leads"
              />
            </div>
            <!-- Qual é o preço médio do lead?   -->
            <div class="container-marketing" @mouseenter="inputHover = 'lead_value'">
              <div class="container-label">
                <span id="investment" class="name"
                  >Preço médio do lead {{ show_lead_value ? 'R$ ' : '' }}
                </span>
                <span
                  v-show="!show_lead_value"
                  id="investment"
                  class="value"
                  @click="(show_lead_value = true), validShow('lead_value')"
                  >R$ {{ lead_value | formatMoney }}</span
                >
                <input
                  v-show="show_lead_value"
                  id="lead_value"
                  v-model="lead_value"
                  class="reset-money"
                  step="0.01"
                  @blur="show_lead_value = false"
                  min="0"
                  max="100"
                  type="number"
                />
              </div>
              <input
                ref="lead_value2"
                id="custom-range"
                type="range"
                min="0"
                max="100"
                step="1"
                data-max="100"
                v-model="lead_value"
              />
            </div>
            <!-- Conversão -->
            <div class="container-marketing explain">
              {{ textExplain }}
            </div>
          </div>
        </div>        
        <div :style=" step > 4 ? 'transform: translateX(-100%); ' : step < 4 ? 'transform: translateX(100%);' : ''"  v-if="hasStrategic">
          <div class="mb-4">
            <p style="text-align: center;">Com esses dados, é possível realizar previsões e definir metas mais claras. Você pode criar diferentes planos, edita-los e acompanha-los de perto na aba "estratégico".</p>
          </div>
          <div class="container-all-marketing">
            <div class="container-marketing-info" @mouseenter="resultHover = 'leads'">
              <span id="investment" class="name"
                >Quantidade de leads {{ (leads * (profit / product)) | formatNumber }}</span
              >
            </div>
            <div class="container-marketing-info" @mouseenter="resultHover = 'sales'">
              <span id="investment" class="name">Quantidade de vendas - {{ (profit / product) | formatNumber }}</span>
            </div>
            <div class="container-marketing-info" @mouseenter="resultHover = 'profit'">
              <span id="investment" class="name">Lucro final - R${{ calcProfit | formatMoney }}</span>
            </div>
            <div class="container-marketing-info" @mouseenter="resultHover = 'investment'">
              <span id="investment" class="name"
                >Investimentos - R${{ (leads * (profit / product) * lead_value) | formatMoney }}
              </span>
            </div>
            <div class="container-marketing explain">
              {{ textExplainResult }}
            </div>
          </div>
        </div>
        <div :style=" step > totalSteps ? 'transform: translateX(-100%); ' : step < totalSteps ? 'transform: translateX(100%);' : ''">
          <div class="mb-4">
            <p style="text-align: center;">Perfeito, agora você pode utilizar todo o potêncial da {{ nameSystem }} para alcançar tudo isso. Fique a vontade para para descobrir os recursos que temos a oferecer!</p>
          </div>
          <div class="container-all-marketing">
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M224,56V200a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z" opacity="0.2"></path><path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM184,96a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,96Zm0,32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,128Zm0,32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,160Z"></path></svg>
              Crie formulários personalizados
            </div>
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M224,56V168a8,8,0,0,1-8,8H192V88a8,8,0,0,0-8-8H64V56a8,8,0,0,1,8-8H216A8,8,0,0,1,224,56Z" opacity="0.2"></path><path d="M216,40H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V184h16a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,88H184v16H40ZM184,200H40V120H184v80Zm32-32H200V88a16,16,0,0,0-16-16H72V56H216Z"></path></svg>
              Faça páginas responsivas e rápidas
            </div>
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M237.66,153,153,237.66a8,8,0,0,1-11.31,0L42.34,138.34A8,8,0,0,1,40,132.69V40h92.69a8,8,0,0,1,5.65,2.34l99.32,99.32A8,8,0,0,1,237.66,153Z" opacity="0.2"></path><path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63Zm-96,96L48,132.69V48h84.69L232,147.31ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z"></path></svg>
              Configure automações de tags
            </div>
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M223.69,42.18l-58.22,192a8,8,0,0,1-14.92,1.25L110,149.81a8,8,0,0,0-3.8-3.8L20.58,105.45a8,8,0,0,1,1.25-14.92l192-58.22A8,8,0,0,1,223.69,42.18Z" opacity="0.2"></path><path d="M227.32,28.68a16,16,0,0,0-15.66-4.08l-.15,0L19.57,82.84a16,16,0,0,0-2.42,29.84l85.62,40.55,40.55,85.62A15.86,15.86,0,0,0,157.74,248q.69,0,1.38-.06a15.88,15.88,0,0,0,14-11.51l58.2-191.94c0-.05,0-.1,0-.15A16,16,0,0,0,227.32,28.68ZM157.83,231.85l-.05.14L118.42,148.9l47.24-47.25a8,8,0,0,0-11.31-11.31L107.1,137.58,24,98.22l.14,0L216,40Z"></path></svg>
              Dispare e-mails em massa
            </div>
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M216,48H40a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48ZM96,144a24,24,0,1,1,24-24A24,24,0,0,1,96,144Z" opacity="0.2"></path><path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path></svg>
              Organize as informações dos leads
            </div>
            <div class="container-marketing-info hints">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#4ea934" viewBox="0 0 256 256"><path d="M112,80A32,32,0,1,1,80,48,32,32,0,0,1,112,80Zm64-32a32,32,0,1,0,32,32A32,32,0,0,0,176,48ZM80,144a32,32,0,1,0,32,32A32,32,0,0,0,80,144Z" opacity="0.2"></path><path d="M80,40a40,40,0,1,0,40,40A40,40,0,0,0,80,40Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,80,104Zm96,16a40,40,0,1,0-40-40A40,40,0,0,0,176,120Zm0-64a24,24,0,1,1-24,24A24,24,0,0,1,176,56ZM80,136a40,40,0,1,0,40,40A40,40,0,0,0,80,136Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,80,200Zm136-24a8,8,0,0,1-8,8H184v24a8,8,0,0,1-16,0V184H144a8,8,0,0,1,0-16h24V144a8,8,0,0,1,16,0v24h24A8,8,0,0,1,216,176Z"></path></svg>
              E muito mais
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer="{}">
      <div class="step-bar">
        <div class="progress" :style="`width: ${step * 100 / totalSteps}%`"></div>
      </div>
      <BaseButton v-if="step > 1 && step != totalSteps" variant="link-info" class="mr-3" @click="ignore()" style="display: flex; align-items: center; gap: 10px;">
        Ignorar
      </BaseButton>
      <BaseButton v-if="step == 1 && ajustSubdomain == true && subdomain.length != 0" variant="link-info" class="mr-3" @click="removeCaracteres()" style="display: flex; align-items: center; gap: 10px;">
        Ajustar
      </BaseButton>
      <BaseButton :class="loading ? 'shine' : ''" variant="primary" @click="loading ? '' : nextStep()" style="display: flex; align-items: center; gap: 10px;">
        {{ step == totalSteps ? 'Finalizar' : 'Continuar'}} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffffff" viewBox="0 0 256 256"><path d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"></path></svg>
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import moment from "moment-timezone";
import TokenService from "@/services/resources/TokenService";
import store from "@/store/index";

// Para alterar o subdomínio
import updatePanel from "@/services/resources/UpdatePanel";
const servicePanel = updatePanel.build();

// Para criar o primeiro plano estratégico
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();

// Para alterar a senha
import UsersService from "@/services/resources/UsersService";
const serviceUser = UsersService.build();

export default {
  data() {
    return {
      name_pannel: "",
      subdomain: "",
      loading: false,
      press: true,
      step: 1,
      newPassword: '',
      showPassword: false,
      show_profit: false,
      profit: 10000,
      product: 500,
      show_product: false,
      leads: 10,
      show_leads: false,
      lead_value: 10,
      show_lead_value: false,
      inputHover: '',
      resultHover: '',
      ajustSubdomain:false
    };
  },
  methods: {
    handleTab(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    },

    verifyResource() {
      if (this.$store.getters.isAuthenticated ) {
        this.$store.dispatch("getResource");
      }
    },
    removeCaracteres(){
      this.ajustSubdomain = false;
      this.subdomain = this.subdomain.replace(/[^a-zA-Z\d_-]/g, '');
    },
    openModal(){
      this.name_pannel =  "";
      this.subdomain =  "";
      this.loading =  false;
      this.press =  true;
      if(this.totalSteps == this.step){
        this.$bvModal.hide("verifySubdomain");
      }
    },
    nextStep() {
      if (this.step === this.totalSteps) {
        this.redirect();
        return
      }
      if (this.step === 1) {
        this.requirementsSubdomain();
        return
      }
      if (this.step === 2) {
        this.updatePassword()
        return
      }
      if (this.step === 4) {
        this.createPlan()
        return
      }
      this.step++
    },
    ignore() {
      if (this.step === 3) {
        this.step++
      }
      this.step++
    },
    requirementsSubdomain(){
      if (!this.validationsSubdomain[1].validated) {
        this.$bvToast.toast("Subdominio contém caracteres especiais", {
          title: "Subdomínio inválido",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.ajustSubdomain = true;
        return
      }
      if (this.subdomain.length < 4 || this.subdomain.length > 20) {
        this.$bvToast.toast("Quantidade de caracteres inválida", {
          title: "Subdomínio inválido",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.updatePannel()
    },
    updatePassword(){
      if (this.validations.some(item => !item.validated)) {
        this.$bvToast.toast("Verifique os requisitos de senha", {
          title: "Senha inválida",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      const whiteSpace = /\s/;
      if (whiteSpace.test(this.newPassword)) {
        this.$bvToast.toast("A nova senha não deve conter espaços em branco", {
          title: "Senha inválida",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      var data = {
        id: "change-password",
        password: this.newPassword,
      };
      this.loading = true;
      serviceUser
        .createId(data)
        .then(() => {
        })
        .catch((err) => {
          this.$bvToast.toast("Ocorreu um erro ao alterar a senha, tente novamente mais tarde.", {
            title: "Senha inválida",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
          this.step++
        });
      
    },
    createPlan(){
      let metas = {
        cliques: 0,
        taxa_cartao: 0,
        cpc: "v2",
        investimento: this.profit,
        venda: this.product,
        leads: this.leads,
        imposto_producao: this.lead_value,
        total_profit: this.calcProfit,
        cpl: this.lead_value,
        cpv: this.profit / this.product,
      };
      let data = {
        title: 'Primeiro plano - 1 ano',
        campaigns: [1],
        start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().add(1, 'year').format("YYYY-MM-DD HH:mm:ss"),
        metas: metas,
        pipeline_id: 1
      };
      this.loading = true;
      servicePlan
        .create(data)
        .then((resp) => {
        })
        .catch((error) => {
          this.$bvToast.toast("Erro ao criar plano, tentenovamente mais tarde", {
            title: "Plano",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
          this.step++
        });
    },
    validShow(name) {
      var input = document.getElementById(`${name}`);
      this.$nextTick(() => input.focus());
    },
    updatePannel() {
      if (
        this.name_pannel == "" ||
        this.name_pannel == null ||
        this.subdomain == "" ||
        this.subdomain == null
      ) {
        this.$bvToast.toast("Preencha os campos", {
          title: "Painel",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }


      let data = {};

      if (this.$route.name == "selecionar-painel") {
        data = {
          tenant_id: sessionStorage.getItem("tenant_id"),
          name: this.name_pannel,
          subdomain: this.subdomain,
        };
      } else {
        data = {
          tenant_id: this.$store.getters.user.user.tenant[0].id,
          name: this.name_pannel,
          subdomain: this.subdomain,
        };
      }

      if (
        this.subdomain == "" ||
        this.subdomain == null ||
        this.name_pannel == "" ||
        this.name_pannel === null
      ) {
        this.$bvToast.toast("Preencha os campos.", {
          title: "Painel",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.loading = true;
      servicePanel
        .create(data)
        .then((r) => {
          this.step++
          if (r.success) {
            this.subdomain = r.subdomain
          }
        })
        .catch((err) => {
          this.$bvToast.toast("Subdominio já existe!", {
            title: "Painel",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    redirect() {
      this.loading = true
      let hasAccess = Cookies.get('access_token')
      if (hasAccess) {
        this.$bvModal.hide("verifySubdomain");
        this.loading = true
        Cookies.set('fresh_subdomain', 'true', { expires: 0.005 })
        this.$router.replace(`/${this.subdomain}/home`);
        return
      }
      let service = TokenService.build();
      service
        .login(this.subdomain)
        .then((resp) => {
          Cookies.set('fresh_subdomain', 'true', { expires: 0.005 })
          this.$bvModal.hide("verifySubdomain");
          store.dispatch("blogSelected2", resp);
        })
        .catch((err) => {
          store.dispatch("logoutRequest");
        });
      this.loading = false
    }
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        number = parseFloat(number);
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
    totalSteps() {
      if (this.hasStrategic) {
        return 5
      }
      return 3
    },
    hasStrategic() {
      if (this.$store.state.user && this.$store.state.user.resources && this.$store.state.user.resources.nstrategic) {
        return true
      }
      return false
    },
    calcProfit() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var result = this.profit - i9;
      return result;
    },
    calcRoi() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var calc1 = this.profit - i9;
      var result = calc1 / i9;
      return result;
    },
    validations() {
      let validations = [
        { step: "8 caracteres", validated: this.newPassword.length >= 8 },
        { step: "Letra maiúscula", validated: /[A-Z]/.test(this.newPassword) },
        { step: "Letra minúscula", validated: /[a-z]/.test(this.newPassword) },
        { step: "Caractere numérico", validated: /\d/.test(this.newPassword) },
        { step: "Símbolo especial", validated: /[@$!%*?&^#~.+={}()|\[\]\\\-_]/.test(this.newPassword) },
      ];
      return validations;
    },
    validationsSubdomain(){
      let validations = [
        { step: "Contém entre 4 e 20 digitos", validated: this.subdomain.length >= 4 && this.subdomain.length <= 20 },
        { step: "Não contém caractere especial", validated: !/[^a-zA-Z\d_-]/.test(this.subdomain) && this.subdomain.length != 0},
      ];
      return validations;
    },
    textExplain() {
      switch (this.inputHover) {
        case 'profit':
          return 'Qual é a sua meta de faturamento bruto dentro de um ano?'
        case 'product':
          return 'Qual é o preço médio dos seus produtos?'
        case 'leads':
          return 'Quantos leads você precisa capturar para sair uma venda?'
        case 'lead_value':
          return 'Qual é o valor investido em propaganda para a captura de um lead?'
        default:
          return 'Passe o mouse sobre os inputs para ver detalhes.'
      }
    },
    textExplainResult() {
      switch (this.resultHover) {
        case 'leads':
          return 'Quantos leads você terá que capturar'
        case 'sales':
          return 'Quantas vendas você terá que fazer no total'
        case 'profit':
          return 'Quanto você terá de lucro líquido'
        case 'investment':
          return 'Quanto você terá que investir para captar os leads'
        default:
          return 'Passe o mouse sobre os resultados para ver detalhes.'
      }
    }
  },
  
  mounted() {
    this.verifyResource()
  },
};
</script>
<style lang="scss">
.shine {
  background: linear-gradient(100deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shine 1s linear infinite;
  border-color: var(--white-medium) !important;
  border-radius: 10px;
  cursor: wait;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-moz-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-o-keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.modal:has(#verifySubdomain___BV_modal_content_) {
  margin: auto;
  background: linear-gradient(315deg, color-mix(in lch, var(--bluee) 70%, #0000), color-mix(in lch, var(--greenn) 70%, #0000)) !important;
  max-width: 100vw;
  overflow: hidden;
  &::after, &::before {
    content: '';
    height: 1000px;
    width: 1000px;
    background-image: linear-gradient(90deg, transparent, var(--greenn));
    border-radius: 1500px;
    position: absolute;
    left: calc(45%);
    z-index: -1;
    animation: wave 16s cubic-bezier(0, 1, 1, 0) alternate infinite;
    margin-top: -250px;
  }
  &::before {
    animation: wave 16s cubic-bezier(0, 1, 1, 0) alternate-reverse infinite;
    background-image: linear-gradient(90deg, var(--bluee), transparent);
    left: unset;
    right: calc(45%);
    margin-top: 250px;
  }
  @keyframes wave {
    0% {
      transform: rotate(0deg);
      top: calc(100vh + 2500px);
      opacity: .5;
    }
    100% {
      transform: rotate(750deg);
      top: -3500px;
      opacity: 0;
    }
  }
}

</style>

<style lang="scss" scoped>
input:-internal-autofill-selected {
  background-color: var(--greenn2) !important;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #edf5f4 inset;
}

/* Cor do texto do autocomplete */

.explain {
  height: fit-content !important;
  text-align: center;
  padding: 10px !important;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: var(--greenn2);
}
.container-all-marketing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  font-size: 14px;
  span {
    font-size: inherit
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    padding: 0 !important;
    margin-bottom: 0 !important;
    line-height: 1 !important;
    height: fit-content !important;
    border: none !important;
    border-radius: 0;
  }
  input[type="range"] {
    border: none !important;
    padding: 0px !important;
    height: auto !important;
    /*   -webkit-appearance: none; */
    background-color: var(--greenn2);
    appearance: none !important;
    position: relative !important;
    width: 100% !important;
    cursor: pointer !important;
    height: 4px !important;
    border-radius: 15px !important;
    outline: none !important;
    margin: 0 !important;
  }
  //  Thumb: webkit
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    height: 10px !important;
    width: 10px !important;
    background-color: var(--greenn) !important;
    border-radius: 50% !important;
    border: 2px solid var(--greenn) !important;
    transition: 0.2s ease-in-out !important;
  }
  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 10px !important;
    width: 10px !important;
    background-color: var(--greenn) !important;
    border-radius: 50% !important;
    border: 2px solid var(--greenn) !important;
    transition: 0.2s ease-in-out !important;
  }

  .container-marketing {
    display: grid;
    gap: 10px;
    // padding: 20px;
    height: 78px;
    padding: 0 20px;
    align-content: center;
    border-radius: 7px;
    border: 1px solid #e5e5e5;
  }
  .container-marketing-info {
    border-radius: 7px;
    border: 1px solid #e5e5e5;
    padding: 20px;
    background-color: var(--greenn2);
    transition: .3s;
    cursor: default;
    svg {
      margin-right: 10px;
    }
    &.hints {
      background-color: white;
      padding: 15px 20px;
    }
    &:not(.hints):hover {
      background-color: var(--greenn-transparent);
      border-color: var(--greenn2);
    }
  }
}
.passwork-wrapper {
  position: relative;
  img {
    position: absolute;
    height: 20px;
    top: calc(50% - 14px);
    right: 16px;
    cursor: pointer;
  }
}
.validations {
  display: inline-block;
  line-height: 1;
  color: var(--gray05);
  width: calc(50% - 5px);
  border-radius: 10px;
  padding: 10px;
  border: 1px solid var(--white-medium);
  margin-bottom: 10px;
  &:nth-child(2n) {
    margin-left: 10px;
  }
  span {
    color: inherit;
  }
}
.content-slider {
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 315px;
  position: relative;
  > div {
    width: 100%;
    min-width: 100%;
    transition: .8s;
    position: absolute;
    top: 0;
    left: 0;
    > div:last-child {
      border: 1px solid var(--white-medium);
      border-radius: 10px;
      padding: 15px;
    }
  }
}

.preview-domain {
  font-size: 12px;
  color: var(--gray05);
}
.welcome-banner {
  background-image: linear-gradient(27deg, var(--greenn-transparent), var(--greenn2)) ;
  width: calc(100% + 60px);
  margin: 0 0 45px -30px;
  padding: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: var(--greenn);
  letter-spacing: -1.5%;
}

.step-bar {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  background-color: var(--greenn-transparent);
  height: 8px;
  margin: 0;
  border-radius: 0;
  .progress {
    background-color: var(--greenn);
    height: 100%;
    border-radius: 0;
    transition: .8s;
  }
}

.model-content {
  padding: 20px 30px !important;
  -webkit-animation: showTop 0.4s forwards !important;
  animation: showTop 0.4s forwards !important;
  height: 500px;
}
.modal {
  margin-top: 100px !important;
}

#btns-modal-tenantSubdomain {
  display: flex;
  justify-content: right;
  font-weight: 600;
  width: 100px;
  width: auto;
  color: #fff;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
#salvar-subdomain-tenant:hover {
  transform: scale(1.07);
}
</style>
